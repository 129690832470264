import * as React from "react";
import { EGDSSheet } from "@egds/react-core/sheet";
import { EGDSToolbar, EGDSToolbarProps } from "@egds/react-core/toolbar";
import { LinksDialogContext } from "components/flexComponents/Links/GridView/LinkDialogContext";
import { ItemKeyHelper } from "components/utility/ItemKeyHelper";
import Links from "components/flexComponents/Links/Links";
import { BlossomComponent } from "src/components/flexFramework/domain/BlossomComponent";
import { TemplateComponent } from "experience-template-renderer-react";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { EGDSScrollable } from "@egds/react-core/scrollable";
import { RegionChildren } from "src/utils/RegionUtils";
import { LinksTemplateComponent } from "../typings";

interface LinkDialogProps {
  id: string;
  onDismiss: () => void;
  blossomComponent: BlossomComponent;
  templateComponents: LinksTemplateComponent[];
  isOpen: boolean;
}

export const LinkDialog = (props: LinkDialogProps) => {
  const { id, onDismiss, blossomComponent, templateComponents, isOpen } = props;
  const linksDialogContext = LinksDialogContext;

  const keyHelper = new ItemKeyHelper(`LinkDialog-${id}`);

  const toolBarAttributes: EGDSToolbarProps = {
    toolbarTitle: linksDialogContext.dialogHeading,
    navigationContent: {
      navIconLabel: "Close",
      onClick: onDismiss,
    },
  };

  const triggerRef = React.createRef<HTMLElement>();

  const linkItems = linksDialogContext.modules.map((module, index) => {
    if (module.type === "link-juice" && templateComponents && blossomComponent) {
      const linksJuiceModule = templateComponents.filter((groupItem: TemplateComponent) => {
        /* istanbul ignore next */
        if (groupItem.children) {
          const [element] = groupItem.children;
          return element.metadata?.id === module.metadata.id;
        }
        return false;
      });
      // Asserted none null children in the filter
      return (
        <RegionChildren
          key={`link-dialog-item-${index}`}
          templateComponent={linksJuiceModule[0]}
          blossomComponent={blossomComponent}
        />
      );
    }
    return <Links key={keyHelper.next()} templateComponent={module} insideTabGroupParam />;
  });

  return (
    <EGDSSheet type="centered" centeredSheetSize="small" triggerRef={triggerRef} isVisible={isOpen}>
      <div>
        <EGDSToolbar {...toolBarAttributes} />
      </div>
      <EGDSSpacing padding={{ blockstart: "one" }} margin="one">
        <EGDSScrollable direction="vertical">
          <div className="LinkDialog">{linkItems}</div>
        </EGDSScrollable>
      </EGDSSpacing>
    </EGDSSheet>
  );
};
